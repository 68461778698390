








































import { Component, Vue } from "vue-property-decorator";
import NavItem from "@/views/components/NavItem.vue";

@Component({
  components: { NavItem }
})
export default class App extends Vue {
  private topic = require("@/assets/images/topic.jpg");
  private qr = require("@/assets/images/qr.jpg");

  openQrDialog() {
    console.log(1);
  }
}
