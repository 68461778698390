








import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class NavItem extends Vue {
  @Prop()
  label!: string;

  @Prop({
    default: ""
  })
  route!: string;
}
