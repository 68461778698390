import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "normalize.css";
import "lib-flexible";
import "ant-design-vue/dist/antd.css";
import "@/assets/public.styl";

Vue.config.productionTip = false;

// 加载UI框架
import antd from "ant-design-vue";
Vue.use(antd);

export default new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
