import Vue from "vue";
import Router, { RouteConfig } from "vue-router";
import Layout from "@/views/Layout.vue";

Vue.use(Router);

export const routes: RouteConfig[] = [
  {
    name: "root",
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        name: "home",
        path: "home",
        meta: {
          title: "首页"
        },
        component: () => import("@/views/page/Home.vue")
      },
      {
        name: "space",
        path: "space",
        meta: {
          title: "树生态空间"
        },
        component: () => import("@/views/page/Space.vue")
      },
      {
        name: "school",
        path: "school",
        meta: {
          title: "树行课堂"
        },
        component: () => import("@/views/page/School.vue")
      }
    ]
  }
];

const router = new Router({
  routes: routes
});

export default router;
